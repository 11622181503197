import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/kpi/modules/reporting/types";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import { RouteNames } from "@/router/kpi/routes";
import router from "@/router/kpi";
import routeTypes from "@/store/shared/route/types";
import { saveAs } from "file-saver";
import { last, isNil } from "lodash";
import { formatFullName } from "@/utils/formatting";
import ReportingRouteQuery from "@/store/kpi/modules/reporting/types/reportingRouteQuery";
import ReportingRouteService from "@/store/kpi/modules/reporting/services/reportingRouteService";
import ReportingState from "@/store/kpi/modules/reporting/types/reportingState";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import ReportingFilter from "@/store/kpi/modules/reporting/types/reportingFilter";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { getQuarter } from "date-fns";
import { ApiKpiReportUser } from "@/api/kpi/types/apiKpiReportUser";
import { ReportingItem } from "@/store/kpi/modules/reporting/types/reportingItem";
import { ReportingEditableItemMapper } from "@/store/kpi/modules/reporting/types/reportingEditableItem";
import ReportingMapper from "@/store/kpi/modules/reporting/mapper";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";
import { refreshRoute } from "@/utils/router";
import ReportingRouteParams from "@/store/kpi/modules/reporting/types/reportingRouteParams";
import contentDispositionParser from "content-disposition-parser";
import { i18n } from "@/plugins";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { KpiStorageController } from "@/api/kpi/storage";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);
const kpiStorageController = new KpiStorageController(abortService);

const defaultRouteQuery = new ReportingRouteQuery("", []);
const defaultRouteParams = new ReportingRouteParams(0, null);

const routeService = new ReportingRouteService(defaultRouteQuery, defaultRouteParams);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<ReportingState>()).build();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableItems"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new ReportingState(
			new ListingModel<ApiKpiReportUser>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new ReportingFilter(),
			routeMixin.state(),
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<ReportingState>;

const getters = <GetterTree<ReportingState, any>>{
	...listingMixin.getters,
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.availableUsers]: state => {
		return state.users.filter(user => state.listing.items.some(x => x.id === user.id));
	},
	[getterTypes.availableKpis]: state => {
		return state.kpis.filter(kpi => state.listing.items.some(x => x.kpis.some(y => y.id === kpi.id)));
	},
	[getterTypes.evaluationReportFileUrl]: state => {
		return state.evaluationReportFile && URL.createObjectURL(state.evaluationReportFile);
	},
	[getterTypes.filteredAndSortedItems]: (state): ApiKpiReportUser[] => {
		return state.listing.items.map(staff => {
			return {
				...staff,
				kpis: staff.kpis.filter(kpi => {
					if(!(!state.filter.staffIds.length || state.filter.staffIds.includes(staff.id)))
						return false;

					return !state.filter.kpiId || kpi.id === state.filter.kpiId;
				})
			};
		}).sort((a, b) => formatFullName(a).localeCompare(formatFullName(b)));
	},
	[getterTypes.formattedItems]: (state, getters): ReportingItem[] => {
		const result: any[] = [];

		(getters.filteredAndSortedItems as ApiKpiReportUser[]).forEach((staff, i) => {
			result.push(...staff.kpis.map(kpi => {
					const sortedCriterias = kpi.criterias.sort((a, b) => a.sort - b.sort);
					const completedCriteria = sortedCriterias.find(x => x.isCompleted);

					return {
						order: i,
						userId: staff.id,
						kpiId: kpi.id,
						userName: formatFullName(staff),
						kpiName: kpi.name,
						laborRate: staff.laborRate,
						testingRate: staff.testingRate,
						workingRate: staff.workingRate,
						position: staff.position,
						department: staff.department,
						pecularity: staff.pecularity,
						bonus: staff.bonus,
						criterias: sortedCriterias,
						weights: completedCriteria ?
							[isNil(completedCriteria.weight) ? i18n.t("common.no") : completedCriteria.weight] :
							sortedCriterias.map(x => isNil(x.weight) ? i18n.t("common.no") : x.weight),
						completion: kpi.criterias.find(x => x.isCompleted)?.completion,
						justification: kpi.criterias.find(x => x.isCompleted)?.justification,
						evaluationReport: staff.evaluationReport
					};
				})
			);
		});

		return result;
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<ReportingState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<ReportingState>(rootState, namespace);

	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !mutation.payload.to.query.year) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.reconstituteRoute));
			} else if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		// Фильтрация на фронте
		case resolveMutation(namespace, mutationTypes.RESET_FRONT_FILTER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_KPI_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STAFF_IDS):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			break;
		// Фильтрация на бэке
		case resolveMutation(namespace, mutationTypes.SET_FILTER_YEAR):
			if(state.isInitialized)
				subscribersManager.dispatch(resolveAction(namespace, actionTypes.fetchKpis), true);
		case resolveMutation(namespace, mutationTypes.SET_FILTER_QUARTER):
			if(state.isInitialized)
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.RESET_FRONT_FILTER));
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		{
			subscribersManager.commit(resolveMutation(namespace, mutationTypes.RESET_EDITABLE_ITEMS));

			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await Promise.all([
					subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems)),
					subscribersManager.dispatch(resolveAction(namespace, actionTypes.fetchPermissions))
				]);
			});

			break;
		}
	}
};

const actions = <ActionTree<ReportingState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);

		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);

		unsubscribeCallback = subscribersManager.subscribe(subscribe);

		await dispatch(actionTypes.fetchPeriods);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);

		try {
			if(state.filter.quarter === KpiQuarterEnum.YEAR) {
				let { staffs } = await kpiController.getReportingByYear(state.filter.year);

				commit(mutationTypes.SET_LISTING_ITEMS, staffs);
			} else {
				let { staffs } = await kpiController.getReportingByQuarter(state.filter.year, +state.filter.quarter!);

				commit(mutationTypes.SET_LISTING_ITEMS, staffs);
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);

		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		let routeParams = await routeService.resolveRouteParams(rootState.route.params);

		if(state.filter.year !== routeParams.year)
			commit(mutationTypes.SET_FILTER_YEAR, routeParams.year);
		if(state.filter.quarter !== routeParams.quarter)
			commit(mutationTypes.SET_FILTER_QUARTER, routeParams.quarter);
		commit(mutationTypes.SET_FILTER_KPI_ID, routeQuery.kpiId);
		commit(mutationTypes.SET_FILTER_STAFF_IDS, routeQuery.staffIds);

		// Если при открытии страницы уже стоят фильтры, нужно сразу загрузить соответствующие справочники
		if(routeQuery.kpiId)
			await dispatch(actionTypes.fetchKpis);
		if(routeQuery.staffIds.length)
			await dispatch(actionTypes.fetchUsers);

		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.push({
			name: RouteNames.REPORTING,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);

		await router.replace({
			name: RouteNames.REPORTING,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});

		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchUsers]({ commit, state, dispatch }) {
		if(state.users.length > 0)
			return;

		commit(mutationTypes.SET_IS_USERS_LOADING, true);

		try {
			const items = await kpiController.getStaffList();

			const users = items.map(x => ({ ...x, fullName: formatFullName(x) }));

			commit(mutationTypes.SET_USERS, users.sort((a, b) => a.fullName.localeCompare(b.fullName)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_USERS_LOADING, false);
		}
	},
	async [actionTypes.fetchKpis]({ commit, state, dispatch }, forced: boolean) {
		if(state.kpis.length > 0 && !forced)
			return;

		commit(mutationTypes.SET_IS_KPIS_LOADING, true);

		try {
			const { kpis } = await kpiController.getKpisByYear(state.filter.year);

			commit(mutationTypes.SET_KPIS, kpis);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_KPIS_LOADING, false);
		}
	},
	async [actionTypes.fetchPeriods]({ commit, state, dispatch }) {
		if(state.periods.length > 0)
			return;

		commit(mutationTypes.SET_IS_PERIODS_LOADING, true);

		try {
			const items = await kpiController.getPeriods(true);
			const year = items.some(x => x.year === state.filter.year) ? state.filter.year : last(items)!.year;
			const quarter = state.filter.quarter === KpiQuarterEnum.YEAR ||
			items.filter(x => x.year === year).some(x => x.quarter === +state.filter.quarter!) ?
				state.filter.quarter :
				String(getQuarter(new Date()));

			commit(mutationTypes.SET_PERIODS, items);
			commit(mutationTypes.SET_FILTER_YEAR, year);
			commit(mutationTypes.SET_FILTER_QUARTER, quarter);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_PERIODS_LOADING, false);
		}
	},
	async [actionTypes.save]({ commit, state, getters, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);
		const user = (getters.formattedItems as ReportingItem[]).find(x => state.editableItems[0].userId === x.userId);

		try {
			await kpiController.setStaffCriteriaResult(user!.userId, ReportingMapper.mapToApiSetStaffCriteriaResult(state));

			if(!state.editableItems.every(x => ((!x.laborRate ? undefined : +x.laborRate) === user!.laborRate?.value) &&
				(x.laborRateJustification || undefined) === user!.laborRate?.justification))
				await kpiController.setStaffLaborRate(user!.userId, ReportingMapper.mapToApiSetStaffLaborRateRequest(state));

			if((user!.pecularity || "") !== state.editableItems[0].pecularity)
				await kpiController.setStaffPecularity(user!.userId, ReportingMapper.mapToApiSetStaffPecularityRequest(state));

			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);

			await refreshRoute(router);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	},
	async [actionTypes.updateEditableItems]({ commit, state, getters }, userId: string) {
		const items = (getters.formattedItems as ReportingItem[]).filter(x => x.userId === userId);
		const editableItems = items.map(x => ReportingEditableItemMapper.map(x));

		commit(mutationTypes.SET_EDITABLE_ITEMS, editableItems);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.fetchPermissions]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, true);

		try {
			if(state.filter.quarter === KpiQuarterEnum.YEAR) {
				const { staffPermissions } = await kpiController.getReportingPermissionsByYear(state.filter.year);
				commit(mutationTypes.SET_PERMISSIONS, staffPermissions);
			} else {
				const { staffPermissions } = await kpiController.getReportingPermissionsByQuarter(state.filter.year, state.filter.quarter!);
				commit(mutationTypes.SET_PERMISSIONS, staffPermissions);
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, false);
		}
	},
	async [actionTypes.downloadScoreCards]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_SCORE_CARDS_DOWNLOADING, true);

		try {
			const payload = [];

			if(state.downloadScoreCardsRequest.period === KpiQuarterEnum.ALL) {
				const quarters = Object.values(KpiQuarterEnum).filter(x => x !== KpiQuarterEnum.ALL);

				quarters.forEach(x => payload.push({
					year: state.downloadScoreCardsRequest.year!,
					quarter: x === KpiQuarterEnum.YEAR ? null : +x
				}));
			} else
				payload.push({
					year: state.downloadScoreCardsRequest.year!,
					quarter: state.downloadScoreCardsRequest.period === KpiQuarterEnum.YEAR ?
						null :
						+state.downloadScoreCardsRequest.period!
				});

			const { data, headers } = await kpiController.getScoreCards(payload);
			const { filename } = contentDispositionParser(headers["content-disposition"]);

			saveAs(data, filename);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_SCORE_CARDS_DOWNLOADING, false);
		}
	},
	async [actionTypes.signScoreCard]({ commit, state, dispatch, getters },
		{ staffId, signature }: { staffId: string, signature: string })
	{
		commit(mutationTypes.SET_IS_SIGNING, true);

		try {
			if(state.filter.quarter === KpiQuarterEnum.YEAR) {
				await kpiController.signScoreCardByYear(staffId, state.filter.year, signature);
			} else {
				await kpiController.signScoreCardByQuarter(staffId,
					state.filter.year,
					state.filter.quarter as string,
					signature);
			}

			await dispatch(actionTypes.updateListingItems);

			alertService.addInfo(AlertKeys.SUCCESS_SIGNED);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_SIGNING, false);
		}
	},
	async [actionTypes.fetchSigningFile]({ commit, state, dispatch }, fileId) {
		commit(mutationTypes.SET_IS_FILE_LOADING, true);

		try {
			const [file, meta] = await Promise.all([
				kpiStorageController.getFile(fileId),
				kpiStorageController.getFileMeta(fileId)
			]);

			commit(mutationTypes.SET_EVALUATION_REPORT_FILE, file);
			commit(mutationTypes.SET_EVALUATION_REPORT_FILE_META, file);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_FILE_LOADING, false);
		}
	},
	async [actionTypes.downloadOriginal]({ commit, state, dispatch }, fileId) {
		saveAs(state.evaluationReportFile!, state.evaluationReportFileMeta!.name);
	},
	async [actionTypes.downloadSigned]({ commit, state, dispatch }, fileId) {
		commit(mutationTypes.SET_IS_SIGNED_FILE_LOADING, true);

		try {
			const [file, meta] = await Promise.all([
				kpiStorageController.getFile(fileId),
				kpiStorageController.getFileMeta(fileId)
			]);

			saveAs(file, meta.name);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
			if(error.constructor !== AccessForbiddenException) {
				console.error(error);
				AlertHelper.handleGeneralRequestErrors(error);
			}
		} finally {
			commit(mutationTypes.SET_IS_SIGNED_FILE_LOADING, false);
		}
	}
};

const mutations = <MutationTree<ReportingState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_FILTER_YEAR](state, value) {
		state.filter.year = value;
	},
	[mutationTypes.SET_FILTER_QUARTER](state, value) {
		state.filter.quarter = value;
	},
	[mutationTypes.SET_FILTER_KPI_ID](state, value) {
		state.filter.kpiId = value;
	},
	[mutationTypes.SET_FILTER_STAFF_IDS](state, value) {
		state.filter.staffIds = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new ReportingFilter(last(state.periods)!.year, String(getQuarter(new Date())) as KpiQuarterEnum);
	},
	[mutationTypes.RESET_FRONT_FILTER](state) {
		state.filter = new ReportingFilter(state.filter.year, state.filter.quarter);
	},
	[mutationTypes.SET_IS_USERS_LOADING](state, value) {
		state.isUsersLoading = value;
	},
	[mutationTypes.SET_IS_KPIS_LOADING](state, value) {
		state.isKpisLoading = value;
	},
	[mutationTypes.SET_IS_PERIODS_LOADING](state, value) {
		state.isPeriodsLoading = value;
	},
	[mutationTypes.SET_KPIS](state, value) {
		state.kpis = value;
	},
	[mutationTypes.SET_PERIODS](state, value) {
		state.periods = value;
	},
	[mutationTypes.SET_USERS](state, value) {
		state.users = value;
	},
	[mutationTypes.SET_EDITABLE_ITEMS](state, value) {
		state.editableItems = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEMS](state) {
		state.editableItems = [];
	},
	[mutationTypes.SET_EDITABLE_ITEM_SELECTED_CRITERIA_ID](state, { id, value }) {
		state.editableItems[state.editableItems.findIndex(x => x.kpiId === id)].selectedCriteriaId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_COMPLETION](state, { id, value }) {
		state.editableItems[state.editableItems.findIndex(x => x.kpiId === id)].completion = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_JUSTIFICATION](state, { id, value }) {
		state.editableItems[state.editableItems.findIndex(x => x.kpiId === id)].justification = value;
	},
	[mutationTypes.SET_IS_PERMISSIONS_LOADING](state, value) {
		state.isPermissionsLoading = value;
	},
	[mutationTypes.SET_PERMISSIONS](state, value) {
		state.permissions = value;
	},
	[mutationTypes.SET_IS_SCORE_CARDS_DOWNLOADING](state, value) {
		state.isScoreCardsDownloading = value;
	},
	[mutationTypes.SET_DOWNLOAD_SCORE_CARDS_REQUEST_YEAR](state, value) {
		state.downloadScoreCardsRequest.year = value;
	},
	[mutationTypes.SET_DOWNLOAD_SCORE_CARDS_REQUEST_PERIOD](state, value) {
		state.downloadScoreCardsRequest.period = value;
	},
	[mutationTypes.SET_IS_SIGNING](state, value) {
		state.isSigning = value;
	},
	[mutationTypes.SET_EVALUATION_REPORT_FILE](state, value) {
		state.evaluationReportFile = value;
	},
	[mutationTypes.SET_EVALUATION_REPORT_FILE_META](state, value) {
		state.evaluationReportFileMeta = value;
	},
	[mutationTypes.SET_IS_SIGNED_FILE_LOADING](state, value) {
		state.isSignedFileLoading = value;
	},
	[mutationTypes.SET_IS_FILE_LOADING](state, value) {
		state.isFileLoading = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const reportingModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default reportingModule;
