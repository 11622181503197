import { ApiCriteria } from "@/api/kpi/types/apiCriteria";
import { ApiKpiPeriod, ApiKpiPeriodMapper } from "@/api/kpi/types/apiKpiPeriod";
import { KpiMainInfo } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";

export interface ApiAddKpiRequest {
	title: string;
	criterias: ApiCriteria[];
	staffIds: string[];
	periods: ApiKpiPeriod[];
	isRegular: boolean;
}

export class ApiAddKpiRequestHelper {
	static map(source: KpiMainInfo): ApiAddKpiRequest {
		return {
			...source,
			periods: source.periods.map(x => ApiKpiPeriodMapper.map(x))
		};
	}
	
	static getEmpty(): ApiAddKpiRequest {
		return {
			title: "",
			criterias: [],
			staffIds: [],
			periods: [],
			isRegular: false
		};
	}
}
