import ReportingState from "@/store/kpi/modules/reporting/types/reportingState";
import { ApiSetStaffCriteriaResultRequest } from "@/api/kpi/types/apiSetStaffCriteriaResultRequest";
import { ApiSetStaffPecularityRequest } from "@/api/kpi/types/apiSetStaffPecularityRequest";
import { ApiSetStaffLaborRateRequest } from "@/api/kpi/types/apiSetStaffLaborRateRequest";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";

export default class ReportingMapper {
	static mapToApiSetStaffCriteriaResult(source: ReportingState): ApiSetStaffCriteriaResultRequest {
		return {
			period: {
				year: source.filter.year,
				quarter: source.filter.quarter !== KpiQuarterEnum.YEAR ? +source.filter.quarter! : null
			},
			completions: source.editableItems.map(x => ({
				kpiId: x.kpiId,
				criteriaId: x.selectedCriteriaId!,
				completion: +x.completion,
				justification: x.justification
			}))
		};
	}
	
	static mapToApiSetStaffLaborRateRequest(source: ReportingState): ApiSetStaffLaborRateRequest {
		return {
			period: {
				year: source.filter.year,
				quarter: source.filter.quarter !== KpiQuarterEnum.YEAR ? +source.filter.quarter! : null
			},
			staffId: source.editableItems[0].userId,
			laborRate: +source.editableItems[0].laborRate,
			justification: source.editableItems[0].laborRateJustification
		};
	}
	
	static mapToApiSetStaffPecularityRequest(source: ReportingState): ApiSetStaffPecularityRequest {
		return {
			staffId: source.editableItems[0].userId,
			pecularity: source.editableItems[0].pecularity
		};
	}
}
