import { ApiStaffKpi } from "@/api/kpi/types/apiStaffKpi";
import { KpiPeriodMapper } from "@/types/kpi/KpiPeriod";
import { StaffKpi } from "@/types/kpi/staffKpi";

export class KpiCriteriasMapper {
	static mapToStaffKpi(source: ApiStaffKpi): StaffKpi {
		return {
			...source,
			period: KpiPeriodMapper.map(source.period)
		};
	}
}