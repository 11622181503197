import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/kpi/modules/kpi/modules/mainInfo/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import { resolveNestedState } from "@/utils/vuexModules";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import KpiMainInfoState from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoState";
import { chain, cloneDeep } from "lodash";
import { KpiMainInfoMapper } from "@/store/kpi/modules/kpi/modules/mainInfo/mapper";
import storeManager from "@/store/manager";
import KpiState from "@/store/kpi/modules/kpi/types/kpiState";
import { KpiMainInfoModeTypeEnum } from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoModeTypeEnum";
import { KpiMainInfoHelper } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";
import { Criteria, CriteriaHelper } from "@/store/kpi/modules/kpi/types/criteria";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import router from "@/router/kpi";
import { RouteNames } from "@/router/kpi/routes";
import { ApiAddKpiRequestHelper } from "@/api/kpi/types/apiAddKpiRequest";
import { ApiUpdateKpiRequestMapper } from "@/api/kpi/types/apiUpdateKpiRequest";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";
import { KpiPeriod } from "@/types/kpi/KpiPeriod";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);
const mapper = new KpiMainInfoMapper();

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new KpiMainInfoState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<KpiMainInfoState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.formattedEditableItemPeriods]: state => {
		return chain(state.editableItem.periods).groupBy(x => x.year).map((value, key) => {
			// @ts-ignore
			return { year: key, quarters: value.map(x => x.quarter).sort((a, b) => a - b) };
		}).value();
	},
	[getterTypes.isReadMode]: state => {
		return state.mode === KpiMainInfoModeTypeEnum.READ;
	},
	[getterTypes.isEditMode]: state => {
		return state.mode === KpiMainInfoModeTypeEnum.EDIT;
	}
};

const actions = <ActionTree<KpiMainInfoState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state, rootState }, { id }) {
		await dispatch(actionTypes.initializeBase);
		
		commit(mutationTypes.SET_ID, id);
		
		if(state.id) {
			const { kpi } = resolveNestedState<KpiState>(rootState, storeManager.kpi.kpi.namespace);
			
			const kpiMainInfo = mapper.mapToKpiMainInfo(kpi);
			commit(mutationTypes.SET_EDITABLE_ITEM, kpiMainInfo);
		} else {
			const emptyEditableItem = CriteriaHelper.getEmpty();
			
			commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, [emptyEditableItem]);
			commit(mutationTypes.SET_MODE, KpiMainInfoModeTypeEnum.EDIT);
		}
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
	},
	async [actionTypes.addEditableItemCriteria]({ commit, state }) {
		const rawNewEmptyItem = CriteriaHelper.getEmpty();
		
		const mappedEditableItems = state.editableItem.criterias.map((x: Criteria, index: number) => ({ ...x, index, sort: (index + 1) * 100 }));
		
		const newEmptyItemIndex = mappedEditableItems.length;
		const newEmptyItem = { ...rawNewEmptyItem, index: newEmptyItemIndex, sort: (newEmptyItemIndex + 1) * 100 };
		
		const sortedCriterias = mappedEditableItems.sort((a, b) => a.sort - b.sort);
		
		commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, [...sortedCriterias, newEmptyItem]);
	},
	async [actionTypes.deleteEditableItemCriteria]({ commit, state }, { index, id }) {
		// удаляем по индексу айтем, потом мы проходим по всем editableItems и обновляем у всех в модели индекс, исходя из текущего индекса.
		let editableCriteriaItems = cloneDeep(state.editableItem.criterias);
		editableCriteriaItems.splice(index, 1);
		
		const mappedEditableCriteriaItems = editableCriteriaItems.map((x: Criteria, index: number) => ({ ...x, index }));
		
		commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, mappedEditableCriteriaItems);
	},
	async [actionTypes.addEditableItemPeriods]({ commit, state }, { year, quarters }) {
		const periodsWithoutSelectedYear = state.editableItem.periods.filter(x => x.year !== +year);
		
		const formattedQuartersWithSelectedYear = quarters.map((x: number | null) => ({ year, quarter: x }));
		
		commit(mutationTypes.SET_EDITABLE_ITEM_PERIODS, [...periodsWithoutSelectedYear, ...formattedQuartersWithSelectedYear]);
	},
	async [actionTypes.deleteEditableItemFullPeriod]({ commit, state, getters }, { year }) {
		if(getters.isReadMode)
			return;
		
		commit(mutationTypes.SET_EDITABLE_ITEM_PERIODS, state.editableItem.periods.filter(x => x.year !== +year));
	},
	async [actionTypes.deleteEditableItemPeriodsByQuarter]({ commit, state, getters }, { year, quarter }) {
		if(getters.isReadMode)
			return;
		
		commit(mutationTypes.DELETE_EDITABLE_ITEM_PERIODS_ITEM, { year, quarter });
	},
	async [actionTypes.setRegularIndicator]({ commit, rootState, state, getters }, value) {
		if(!value) {
			commit(mutationTypes.SET_EDITABLE_ITEM_IS_REGULAR, value);
		} else {
			// если регулярный показатель true, то мы проставляем все существующие кварталы по выбранным годам
			const { periods } = resolveNestedState<KpiState>(rootState, storeManager.kpi.kpi.namespace);
			
			commit(mutationTypes.SET_EDITABLE_ITEM_PERIODS, periods);
			commit(mutationTypes.SET_EDITABLE_ITEM_IS_REGULAR, value);
		}
	},
	async [actionTypes.save]({ dispatch, commit, state }, { id }) {
		commit(mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_SAVING, true);
		
		try {
			dispatch(actionTypes.remapSortCriteriaItems);
			
			if(id) {
				const payload = ApiUpdateKpiRequestMapper.map(state.editableItem);
				
				await kpiController.updateKpi(id, payload);

				alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);

				// нужно перезапускать страничку без обновления, чтобы левая и правая части синхронизировались
				commit(mutationTypes.SET_KPI_MAIN_INFO_UPDATED, payload);
			} else {
				const payload = ApiAddKpiRequestHelper.map(state.editableItem);
				
				id = await kpiController.createKpi(payload);
				
				alertService.addInfo(AlertKeys.SUCCESS_CREATED_INFO);
				await router.push({ name: RouteNames.KPI, params: { id } });
			}
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_SAVING, false);
		}
	},
	[actionTypes.remapSortCriteriaItems]({ commit, state, dispatch }) {
		const reformattedSortCriteriaItems = state.editableItem.criterias.map((x: Criteria, index: number) => ({ ...x, index, sort: (index + 1) * 100 }));
		commit(mutationTypes.SET_EDITABLE_ITEM_CRITERIAS, reformattedSortCriteriaItems);
	},
	async [actionTypes.deleteKpi]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_KPI_DELETING, true);
		
		try {
			await kpiController.deleteKpi(state.id);
			
			alertService.addInfo(AlertKeys.KPI_SUCCESS_DELETED);
			await router.push({ name: RouteNames.KPIS });
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_KPI_DELETING, false);
		}
	}
};

const mutations = <MutationTree<KpiMainInfoState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_ID](state, value) {
		state.id = value;
	},
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_SAVING](state, value) {
		state.isKpiMainInfoFormSaving = value;
	},
	[mutationTypes.SET_IS_KPI_DELETING](state, value) {
		state.isKpiDeleting = value;
	},
	[mutationTypes.SET_IS_KPI_MAIN_INFO_FORM_VALID](state, value) {
		state.isKpiMainInfoFormValid = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM](state) {
		state.editableItem = KpiMainInfoHelper.getEmpty();
	},
	[mutationTypes.SET_EDITABLE_ITEM](state, value) {
		state.editableItem = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_TITLE](state, value) {
		state.editableItem.title = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_METHODOLOGY](state, value) {
		state.editableItem.methodology = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_IS_REGULAR](state, value) {
		state.editableItem.isRegular = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_CRITERIAS](state, value) {
		state.editableItem.criterias = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_STAFF_IDS](state, value) {
		state.editableItem.staffIds = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_PERIODS](state, value) {
		state.editableItem.periods = cloneDeep(value);
	},
	[mutationTypes.DELETE_EDITABLE_ITEM_PERIODS_ITEM](state, { year, quarter }) {
		state.editableItem.periods.splice(state.editableItem.periods.findIndex(x => x.year === +year && x.quarter === quarter), 1);
	},
	[mutationTypes.SET_KPI_MAIN_INFO_UPDATED]() {
	}
};

export {
	namespace, state, getters, actions, mutations
};

const kpiMainInfoModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default kpiMainInfoModule;
