<template>
	<v-card class="kpi-card pb-7 px-0 mt-7" v-if="isInitialized">
		<v-row>
			<v-col class="d-flex align-start px-7 py-4">
				<frp-autocomplete item-text="text"
								  item-value="value"
								  v-model="internalFilterValues.year"
								  :items="formattedPeriodYears"
								  :disabled="kpiMainInfoMode === KpiMainInfoModeTypeEnum.EDIT"
								  color="blue"
								  hide-details
								  :placeholder="$t('fields.year.placeholder')">
				</frp-autocomplete>
			</v-col>
		</v-row>
		
		<v-form :readonly="isKpiCriteriasFormSaving || isFormReadonly"
				:disabled="isFormDisabled"
				ref="kpiCriteriasForm"
				v-model="isCriteriasFormValid">
			<v-row>
				<v-col>
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading"
								  hide-default-footer
								  :items="filteredItems"
								  :items-per-page="-1"
								  :mobile-breakpoint="mobileBreakpoint"
								  :options="options"
								  item-key="order"
								  group-by="group"
								  :item-class="(item) => `text-body-2 cy-table-row`"
								  class="kpi-kpi-criterias-info-table d-flex flex-column">
						<template #group="{ items, headers }">
							<!--	GROUP	-->
							
							<!--	HEADER	-->
							<tr class="v-row-group__header">
								<td colspan="5" class="pl-2 pr-10 py-4">
									<div class="d-flex align-center justify-space-between">
										<div class="d-flex align-center">
											<div class="d-flex align-center"
												 style="cursor: pointer"
												 @click="toggleGroupOpened(items[0].group)">
												<frp-icon width="25" height="25" :color="colors.grey.base"
														  src="ico_loan-chevron-down" style="cursor: pointer"
														  v-if="openedGroup.includes(items[0].group)">
												</frp-icon>
												<frp-icon width="25" height="25" :color="colors.grey.base"
														  src="ico_loan-chevron-up" style="cursor: pointer"
														  v-else>
												</frp-icon>
												<span class="text-body-1 primary--text text--darken-1 font-weight-bold">
													{{ getFormattedPeriodText(items[0].group) }}
												</span>
											</div>
										</div>
									</div>
								</td>
							</tr>
							
							<template v-if="openedGroup.includes(items[0].group)">
								<!--	BODY	-->
								<tr v-for="(item, i) in items.filter(x => x.group)" :key="item.group + i">
									<td colspan="1">
										{{ formatFullName(item.staff) }}
									</td>
									<td colspan="2">
										<table class="kpi-kpi-criterias-info-table-weights-subtable" style="width: 100%;">
											<tr v-for="(weight, index) in item.weights" :key="`${weight.criteriaId}-${index}`">
												<td width="56%">
													<span>{{ weight.criteriaTitle }}</span>
												</td>
												<td width="44%">
													<div class="d-flex align-center">
														<frp-text-field v-model="weight.value"
																		type="number"
																		required
																		autocomplete="off"
																		:rules="validation.weight"
																		:disabled="isReadMode || isKpiCriteriasFormSaving || item.hasReport"
																		height="29"
																		style="width: 100%;"
																		class="kpi-kpi-criterias-info-weight-field"
																		:placeholder="$t('fields.criteriaWeight.placeholder')"
																		hide-details>
														</frp-text-field>
														
														<v-tooltip top v-if="isEditMode && item.hasReport">
															<template v-slot:activator="{ on, attrs }">
																<div v-bind="attrs" v-on="on" style="font-size: 18px"
																	 class="d-flex align-center">
																	<v-icon color="blue">mdi-alert-circle-outline</v-icon>
																</div>
															</template>
															
															<span>{{ $t("common.tooltipKpiCriteriaWeightText") }}</span>
														</v-tooltip>
													</div>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</template>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-form>
		
		<div class="d-flex mx-n3 mt-10" style="padding-right: 69px;">
			<v-spacer></v-spacer>
			
			<template v-if="isEditMode">
				<frp-btn elevation="0"
						 :disabled="(!isStateContainsUnsavedChanges() && isCreateMode) || isKpiCriteriasFormSaving"
						 @click="isKpiCriteriasUnsavedChangesDialogOpened = true">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				
				<frp-btn elevation="0"
						 :class="{'px-8' : isCreateMode}"
						 :loading="isKpiCriteriasFormSaving"
						 :disabled="!isCriteriasFormValid || !isStateContainsUnsavedChanges()"
						 dark
						 color="blue"
						 @click="onFormSubmit">
					<span>{{ $t("buttons.save") }}</span>
				</frp-btn>
			</template>
			
			<frp-btn v-if="canEditKpi && isReadMode"
					 elevation="0"
					 class="px-3"
					 color="blue"
					 dark
					 :disabled="kpiMainInfoMode === KpiMainInfoModeTypeEnum.EDIT"
					 @click="setMode(KpiCriteriasModeTypeEnum.EDIT)">
				{{ $t("buttons.edit") }}
			</frp-btn>
			
			<frp-confirm-dialog v-if="isKpiCriteriasUnsavedChangesDialogOpened"
								submit-btn-color="blue"
								submit-btn-dark
								@submit="handleCancelFormSubmit"
								@cancel="isKpiCriteriasUnsavedChangesDialogOpened = false"
								:title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
								:description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
			</frp-confirm-dialog>
		</div>
	</v-card>
	<kpi-kpi-criterias-info-loader v-else></kpi-kpi-criterias-info-loader>
</template>

<script>

import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpInformationMenu from "@/components/common/FrpInformationMenu.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpRadioGroup from "@/components/fields/FrpRadioGroup.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/kpi/routes";
import { namespace } from "@/store/kpi/modules/kpi/modules/criteriasInfo";
import { KpiCriteriasModeTypeEnum } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types/KpiCriteriasModeTypeEnum";
import { getterTypes, actionTypes, mutationTypes } from "@/store/kpi/modules/kpi/modules/criteriasInfo/types";
import { KpiMainInfoModeTypeEnum } from "@/store/kpi/modules/kpi/modules/mainInfo/types/KpiMainInfoModeTypeEnum";
import { getterTypes as kpiHeadModuleGetterTypes } from "@/store/kpi/modules/kpi/types";
import storeManager from "@/store/manager";
import { KpiQuarterEnum } from "@/types/kpi/KpiQuarterEnum";
import { prepareMinNumbersRule, requiredRule } from "@/utils/validation";
import KpiKpiCriteriasInfoLoader from "@/views/kpi/kpi/sections/criteriasInfo/KpiKpiCriteriasInfoLoader.vue";
import KpiKpisArrayItem from "@/views/kpi/kpis/KpiKpisArrayItem.vue";
import { first, last } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { formatFullName } from "@/utils/formatting";

const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers(namespace);
const kpiHeadModuleHelpers = createNamespacedHelpers(storeManager.kpi.kpi.namespace);
const kpiMainInfoModuleHelpers = createNamespacedHelpers(storeManager.kpi.kpi.mainInfo.namespace);

export default {
	mixins: [formMixin, storeModuleBasedPage, authorizationMixin, colorsMixin, listMixin],
	data() {
		return {
			last,
			first,
			formatFullName,
			RouteNames,
			KpiCriteriasModeTypeEnum,
			KpiMainInfoModeTypeEnum,
			KpiQuarterEnum,
			namespace,
			openedGroup: [],
			isKpiCriteriasUnsavedChangesDialogOpened: false,
			internalFilterValues: {
				year: null
			},
			validation: {
				weight: [
					requiredRule(),
					prepareMinNumbersRule({ getMin: () => 0 })
				]
			}
		};
	},
	computed: {
		...kpiHeadModuleHelpers.mapGetters({
			staffUsers: kpiHeadModuleGetterTypes.staffUsers,
			periodYears: kpiHeadModuleGetterTypes.periodYears,
			canEditKpi: kpiHeadModuleGetterTypes.canEditKpi
		}),
		...kpiMainInfoModuleHelpers.mapState({
			kpiMainInfoMode: state => state.mode
		}),
		...mapGetters({
			isReadMode: getterTypes.isReadMode,
			isEditMode: getterTypes.isEditMode,
			kpiStaffKpisPeriodYears: getterTypes.kpiStaffKpisPeriodYears
		}),
		...mapState({
			id: state => state.id,
			state: state => state,
			editableItems: state => state.editableItems,
			periods: state => state.periods,
			isKpiCriteriasFormValid: state => state.isKpiCriteriasFormValid,
			isKpiCriteriasFormSaving: state => state.isKpiCriteriasFormSaving,
			isInitialized: state => state.isInitialized,
			mode: state => state.mode
		}),
		filter() {
			return {
				year: this.internalFilterValues.year
			};
		},
		headers() {
			return [
				{
					text: this.$t("tables.staff"),
					value: "staff",
					width: "40%",
					class: "text-body-1 primary--text text--darken-1 font-weight-bold",
					sortable: false
				},
				{
					text: this.$t("tables.criteria"),
					value: "criterias",
					width: "30%",
					class: "text-body-1 primary--text text--darken-1 font-weight-bold",
					sortable: false
				},
				{
					text: this.$t("tables.criteriaWeightInPercents"),
					value: "weights",
					width: "30%",
					class: "text-body-1 primary--text text--darken-1 font-weight-bold",
					sortable: false
				}
			];
		},
		formattedPeriodYears() {
			return this.kpiStaffKpisPeriodYears.map(x => ({ text: x, value: x }));
		},
		isCriteriasFormValid: {
			get() {
				return this.isKpiCriteriasFormValid;
			},
			set(value) {
				this.setIsKpiCriteriasFormValid(value);
			}
		},
		filteredItems() {
			if(!this.internalFilterValues.year)
				return this.tableItems;
			else
				return this.tableItems.filter(x => x.period.year === this.internalFilterValues.year);
		},
		tableItems() {
			return this.isEditMode ? this.editableItems : this.listing.items;
		}
	},
	methods: {
		...mapMutations({
			setIsKpiCriteriasFormValid: mutationTypes.SET_IS_KPI_CRITERIAS_FORM_VALID,
			setMode: mutationTypes.SET_MODE,
			setFrontFilterYear: mutationTypes.SET_FRONT_FILTER_YEAR
		}),
		...mapActions({
			save: actionTypes.save,
			resetFrontFilter: actionTypes.resetFrontFilter
		}),
		toggleGroupOpened(group) {
			if(this.openedGroup.includes(group))
				this.openedGroup = this.openedGroup.filter(x => x !== group);
			else
				this.openedGroup.push(group);
		},
		getFormattedPeriodText(period) {
			const splittedPeriod = period.split("-");
			const quarter = splittedPeriod[0];
			const year = splittedPeriod[1];
			
			const quarterText = i18n.t(`aliases.kpiQuarter.${quarter === "null" ? KpiQuarterEnum.YEAR : quarter}`);
			
			return `${quarterText} ${year}`;
		},
		setInternalFilterValues() {
			this.internalFilterValues.year = this.filterValues.year;
		},
		handleCancelFormSubmit() {
			this.isKpiCriteriasUnsavedChangesDialogOpened = false;
			this.onCancelFormSubmit();
			
			this.setMode(KpiCriteriasModeTypeEnum.READ);
		},
		async onCancelFormSubmit() {
			await this.cancelChanges();
			this.$nextTick(() => {
				if(!this.isResetValidationDisabled)
					this.$refs.kpiCriteriasForm.resetValidation();
			});
		},
		async onFormSubmit() {
			if(this.$refs.kpiCriteriasForm.validate())
				await this.save();
		},
		resetFilter() {
			this.internalFilterValues.year = last(this.kpiStaffKpisPeriodYears)?.year;
			this.applyFilter();
		},
		applyFilter() {
			this.setFrontFilterYear(this.internalFilterValues.year);
		}
	},
	watch: {
		"internalFilterValues.year": {
			handler(value) {
				if(!value)
					this.resetFrontFilter();
				
				this.applyFilter();
			},
			immediate: true
		},
		filteredItems: {
			handler(value) {
				this.openedGroup = [];
				
				if(!value || !value.length)
					return;
				
				value.forEach(x => {
					this.openedGroup.push(x.group);
				});
			},
			immediate: true
		}
	},
	components: {
		FrpInformationMenu,
		FrpTextarea,
		FrpRadioGroup,
		KpiKpisArrayItem,
		KpiKpiCriteriasInfoLoader,
		FrpBtn,
		FrpConfirmDialog,
		FrpTextField,
		FrpIcon,
		FrpAutocomplete,
		FrpCard
	}
};
</script>

<style lang="scss">
</style>