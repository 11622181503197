<template>
	<frp-dialog :value="value"
				@update:value="$emit('update:value', $event)"
				max-width="325"
				:title="user.userName"
				persistent>
		<template #content="{ onIntersect }">
			<v-form ref="form" v-intersect="onIntersect" v-model="formValid">
				<frp-textarea required
							  v-model="pecularity"
							  :label="$t('fields.kpiPecularity.label')"
							  :placeholder="$t('fields.kpiPecularity.placeholder')">
				</frp-textarea>
			</v-form>
		</template>
		
		<template #footer>
			<frp-btn outlined
					 @click="$emit('update:value', false)"
					 color="primary">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 :disabled="!stateContainsUnsavedChanges || !formValid"
					 @click="handleSave">
				{{ $t("buttons.save") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpBtn from "Components/buttons/FrpBtn";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpTextarea from "Components/fields/FrpTextarea";
import FrpTextField from "Components/fields/FrpTextField";
import { formatFullName } from "Utils/formatting";

export default {
	components: { FrpBtn, FrpTextarea, FrpTextField, FrpDialog },
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		user: Object,
		editable: Object
	},
	data() {
		return {
			formatFullName,
			formValid: false,
			pecularity: ""
		};
	},
	computed: {
		stateContainsUnsavedChanges() {
			return this.pecularity !== (this.user.pecularity || "");
		}
	},
	methods: {
		handleSave() {
			this.$emit("save", { pecularity: this.pecularity });
			this.$emit("update:value", false);
		}
	},
	watch: {
		value: {
			handler(val) {
				if(val) {
					this.pecularity = this.editable.pecularity || this.user.pecularity || "";
					if(this.$refs.form)
						this.$refs.form.resetValidation();
				}
			},
			immediate: true
		}
	}
};
</script>
