import { render, staticRenderFns } from "./KpiReporting.vue?vue&type=template&id=310f19b1"
import script from "./KpiReporting.vue?vue&type=script&lang=js"
export * from "./KpiReporting.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports