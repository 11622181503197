<template>
	<kpi-content-layout v-if="isInitialized" :title="title">
		<v-row>
			<v-col cols="6">
				<kpi-kpi-main-info></kpi-kpi-main-info>
			</v-col>
			<v-col cols="6" v-if="!isCreateMode">
				<kpi-kpi-criterias-info></kpi-kpi-criterias-info>
			</v-col>
		</v-row>
	</kpi-content-layout>
	<kpi-kpi-loader v-else></kpi-kpi-loader>
</template>

<script>
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import KpiKpiMainInfo from "@/views/kpi/kpi/sections/mainInfo/KpiKpiMainInfo.vue";
import KpiKpiCriteriasInfo from "@/views/kpi/kpi/sections/criteriasInfo/KpiKpiCriteriasInfo.vue";
import KpiKpiLoader from "@/views/kpi/kpi/KpiKpiLoader.vue";
import { RouteNames } from "@/router/kpi/routes";
import { createNamespacedHelpers } from "vuex";
import { namespace } from "@/store/kpi/modules/kpi";

const { mapState, mapMutations, mapActions, mapGetters } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, authorizationMixin],
	metaInfo() {
		return { title: this.title };
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			state: state => state,
			isInitialized: state => state.isInitialized,
			kpi: state => state.kpi
		}),
		title() {
			switch (this.$route.name) {
				case RouteNames.KPI:
					return this.$t("metaTitles.kpiCard");
				case RouteNames.KPI_CREATE:
					return this.$t("metaTitles.kpiCardCreate");
			}
		},
		isCreateMode() {
			return !this.$route.params.id;
		}
	},
	created() {
		this.initializeStore();
	},
	components: { KpiContentLayout, KpiKpiMainInfo, KpiKpiCriteriasInfo, KpiKpiLoader }
};
</script>

<style scoped lang="scss">

</style>