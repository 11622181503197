<template>
	<kpi-content-layout :title="filterValues.year ? $t('metaTitles.kpisByYear', { year: filterValues.year }) : $t('metaTitles.kpis')">
		<template #title-append>
			<frp-btn v-if="can(Permissions.KPI_REGISTRY_EXPORT_PDF)"
					 :href="externalUrls.kpiExport"
					 target="_blank"
					 :loading="isExcelDownloading"
					 color="blue" outlined elevation="0">
				{{ $t("buttons.kpiExport") }}
			</frp-btn>
			<frp-btn @click="openReports" v-if="can(Permissions.KPI_REPORT_READ)" color="blue" dark elevation="0">
				{{ $t("buttons.progressReport") }}
			</frp-btn>
			<frp-btn v-if="can(Permissions.KPI_KPI_CREATE)" :to="{ name: RouteNames.KPI_CREATE }" color="blue" dark elevation="0">
				{{ $t("buttons.addKpi") }}
			</frp-btn>
		</template>
		
		<v-card class="kpi-card px-6 py-9"
				style="border-bottom-left-radius: 0; border-bottom-right-radius: 0">
			<div class="d-flex align-center" style="gap: 16px">
				<bod-autocomplete item-text="year"
								  item-value="year"
								  class="kpi-field"
								  hide-details
								  :readonly="isItemsLoading"
								  :loading="isPeriodsLoading"
								  style="width: 110px"
								  :items="uniqBy(periods, x => x.year)"
								  v-model="internalFilterValues.year"
								  color="blue"
								  :placeholder="$t('fields.kpiYear.placeholder')">
				</bod-autocomplete>
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  class="kpi-field"
								  @firstclick="fetchUsers"
								  hide-details
								  multiple
								  :readonly="isItemsLoading"
								  :loading="isUsersLoading"
								  style="width: 276px"
								  :items="availableUsers"
								  v-model="internalFilterValues.staffIds"
								  color="blue"
								  :placeholder="$t('fields.employee.placeholder')">
				</bod-autocomplete>
				<bod-autocomplete item-text="name"
								  item-value="id"
								  class="kpi-field"
								  @firstclick="fetchDepartments"
								  hide-details
								  :readonly="isItemsLoading"
								  :loading="isDepartmentsLoading"
								  style="width: 286px"
								  :items="sortedDepartments"
								  v-model="internalFilterValues.departmentId"
								  color="blue"
								  :placeholder="$t('fields.kpiDepartment.placeholder')">
				</bod-autocomplete>
				
				<frp-text-field hide-details
								class="kpi-field"
								style="width: 286px"
								:readonly="isItemsLoading"
								v-model="internalFilterValues.kpi"
								:placeholder="$t('fields.kpi.placeholder')">
				</frp-text-field>
				
				<bod-autocomplete item-text="text"
								  item-value="value"
								  class="kpi-field"
								  hide-details
								  style="width: 220px"
								  :readonly="isItemsLoading"
								  :items="statusItems"
								  v-model="filterStatus"
								  color="blue"
								  :placeholder="$t('fields.kpiStatus.placeholder')">
				</bod-autocomplete>
				<v-spacer></v-spacer>
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.accept") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-row>
			<v-col class="pb-0" style="border-top: 1px solid var(--v-grey-lighten4)">
				<v-data-table :headers="headers"
							  :loading-text="$t('tables.loading')"
							  :loading="isItemsLoading"
							  hide-default-footer
							  item-key="id"
							  group-by="id"
							  :items="formattedItems"
							  hide-default-header
							  :items-per-page="-1"
							  :custom-sort="sortGroup"
							  :mobile-breakpoint="mobileBreakpoint"
							  :options="options"
							  class="kpi-table clickable-rows d-flex flex-column kpi-shadow mt-4">
					<template #header="{ props, on, isMobile }">
						<v-data-table-header v-bind="props" v-on="on" :mobile="isMobile"></v-data-table-header>
					</template>
					
					<template #group.header="{ items, isOpen, toggle }">
						<td colspan="8" class="px-2 py-4">
							<div class="d-flex align-center">
								<div class="d-flex align-center" @click="toggle" style="cursor: pointer">
									<frp-icon width="25" height="25" src="ico_loan-chevron-down" v-if="isOpen" :color="colors.grey.base"/>
									<frp-icon width="25" height="25" src="ico_loan-chevron-up" v-else :color="colors.grey.base"/>
								</div>
								<router-link target="_blank" class="ml-3" :to="getKpiLink(items[0])">{{ items[0].title }}</router-link>
							</div>
						</td>
					</template>
					
					<template #item="{ item }">
						<tr class="text-body-2 kpi-table-body-row">
							<td v-for="column in headers">
								<kpi-kpis-array-item v-if="column.value === 'criterias'" :item="item.criterias"/>
								<kpi-kpis-array-item v-else-if="column.value === 'quarter1'" :item="item.quarter1"/>
								<kpi-kpis-array-item v-else-if="column.value === 'quarter2'" :item="item.quarter2"/>
								<kpi-kpis-array-item v-else-if="column.value === 'quarter3'" :item="item.quarter3"/>
								<kpi-kpis-array-item v-else-if="column.value === 'quarter4'" :item="item.quarter4"/>
								<kpi-kpis-array-item v-else-if="column.value === 'year'" :item="item.year"/>
								<span v-else>{{ item[column.value] }}</span>
							</td>
						</tr>
					</template>
					<template #foot v-if="isOneUserOnly && !periodsSum.isEmpty">
						<tr class="text-body-2 kpi-table-body-row font-weight-bold">
							<td v-for="column in headers">
								<span v-if="column.value === 'quarter1'">{{ periodsSum.quarter1 }}</span>
								<span v-else-if="column.value === 'quarter2'">{{ periodsSum.quarter2 }}</span>
								<span v-else-if="column.value === 'quarter3'">{{ periodsSum.quarter3 }}</span>
								<span v-else-if="column.value === 'quarter4'">{{ periodsSum.quarter4 }}</span>
								<span v-else-if="column.value === 'year'">{{ periodsSum.year }}</span>
							</td>
						</tr>
					</template>
				</v-data-table>
				
				<frp-bottom-space height="20"></frp-bottom-space>
			</v-col>
		</v-row>
		
		<template #footer>
			<bod-footer></bod-footer>
		</template>
	</kpi-content-layout>
</template>

<script>
import { externalUrls } from "@/constants/kpi/externalUrls";
import { KpisQuarter } from "@/constants/kpi/kpis/KpisQuarter";
import { setQuarterStartDate } from "@/utils/dates";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import KpiContentLayout from "Components/layouts/KpiContentLayout";
import { assign, cloneDeep, isEqual, last } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import { i18n } from "Plugins/index";
import { RouteNames } from "Router/kpi/routes";
import { namespace } from "Store/kpi/modules/kpis";
import { actionTypes, getterTypes, mutationTypes } from "Store/kpi/modules/kpis/types";
import KpisFilter from "Store/kpi/modules/kpis/types/kpisFilter";
import { KpiStatusEnum } from "Types/kpi/kpiStatusEnum";
import { formatFullName } from "Utils/formatting";
import { openRouteInNewTab } from "Utils/router";
import KpiKpisArrayItem from "Views/kpi/kpis/KpiKpisArrayItem";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import { uniqBy } from "lodash";
import { getYear, getQuarter } from "date-fns";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	metaInfo() {
		return {
			title: this.filterValues.year ? i18n.t("metaTitles.kpisByYear", { year: this.filterValues.year }) : i18n.t("metaTitles.kpis")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			uniqBy,
			externalUrls,
			formatFullName,
			KpiStatusEnum,
			headers: [
				{
					text: this.$t("tables.staff"),
					value: "staff",
					width: "20%",
					sortable: false
				},
				{
					text: this.$t("tables.kpiDepartment"),
					value: "department",
					width: "38%",
					sortable: false
				},
				{
					text: this.$t("tables.criteria"),
					value: "criterias",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.quarter1"),
					value: "quarter1",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.quarter2"),
					value: "quarter2",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.quarter3"),
					value: "quarter3",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.quarter4"),
					value: "quarter4",
					width: "6%",
					sortable: false
				},
				{
					text: this.$t("tables.year"),
					value: "year",
					width: "6%",
					sortable: false
				}
			],
			internalFilterValues: {
				year: null,
				staffIds: [],
				departmentId: "",
				kpi: "",
				status: KpiStatusEnum.ACTIVE
			}
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			isUsersLoading: state => state.isUsersLoading,
			isPeriodsLoading: state => state.isPeriodsLoading,
			isExcelDownloading: state => state.isExcelDownloading,
			departments: state => state.departments,
			periods: state => state.periods
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty,
			availableUsers: getterTypes.availableUsers,
			availableDepartments: getterTypes.availableDepartments,
			isOneUserOnly: getterTypes.isOneUserOnly
		}),
		filterStatus: {
			get() {
				return this.internalFilterValues.status;
			},
			set(value)
			{
				this.internalFilterValues.status = value || KpiStatusEnum.ALL;
			}
		},
		filter() {
			return {
				year: this.internalFilterValues.year,
				staffIds: this.internalFilterValues.staffIds,
				departmentId: this.internalFilterValues.departmentId,
				kpi: this.internalFilterValues.kpi,
				status: this.internalFilterValues.status
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			if(!this.periods.length) return true;
			return isEqual(new KpisFilter(last(this.periods).year), this.filterValues);
		},
		sortedDepartments() {
			return this.departments.sort((a, b) => a.name.localeCompare(b.name));
		},
		statusItems() {
			return [KpiStatusEnum.ACTIVE, KpiStatusEnum.NOT_ACTIVE].map(x => ({ value: x, text: this.$t(`aliases.kpiStatus.${x}`) }));
		},
		periodsSum() {
			const getSum = (column) => {
				const isEmpty = !this.formattedItems.length || this.formattedItems.every(x => x[column].every(y => y === ""));
				
				return isEmpty ?
					"" :
					this.formattedItems.map(x => x[column]
					// Максимальное значение в KPI
					.reduce((acc, val) => +acc < +val ? +val : +acc, 0))
					// Сумма максимальных значений
						.reduce((acc, val) => +acc + +val, 0);
			};
			const quarter1 = getSum("quarter1");
			const quarter2 = getSum("quarter2");
			const quarter3 = getSum("quarter3");
			const quarter4 = getSum("quarter4");
			const year = getSum("year");
			const isEmpty = quarter1 === "" && quarter2 === "" && quarter3 === "" && quarter4 === "" && year === "";
			
			return { quarter1, quarter2, quarter3, quarter4, year, isEmpty };
		}
	},
	methods: {
		...mapMutations({
			setFilterYear: mutationTypes.SET_FILTER_YEAR,
			setFilterStaffIds: mutationTypes.SET_FILTER_STAFF_IDS,
			setFilterDepartmentId: mutationTypes.SET_FILTER_DEPARTMENT_ID,
			setFilterKpi: mutationTypes.SET_FILTER_KPI,
			setFilterStatus: mutationTypes.SET_FILTER_STATUS,
			resetFilter: mutationTypes.RESET_FILTER
		}),
		...mapActions({
			downloadFile: actionTypes.downloadFile,
			fetchUsers: actionTypes.fetchUsers,
			fetchDepartments: actionTypes.fetchDepartments
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			if(this.internalFilterValues.year !== this.filterValues.year)
				this.setFilterYear(this.internalFilterValues.year || last(this.periods).year);
			else {
				this.setFilterStaffIds(this.internalFilterValues.staffIds || []);
				this.setFilterDepartmentId(this.internalFilterValues.departmentId);
				this.setFilterKpi(this.internalFilterValues.kpi);
				this.setFilterStatus(this.internalFilterValues.status);
			}
		},
		getKpiLink({ id }) {
			return { name: RouteNames.KPI, params: { id } };
		},
		openReports() {
			return openRouteInNewTab(this.$router, {
				name: RouteNames.REPORTING,
				params: {
					year: String(getYear(new Date())),
					quarter: String(getQuarter(setQuarterStartDate(new Date(), KpisQuarter.START_DAY)))
				}
			});
		},
		sortGroup(items) {
			return cloneDeep(items).sort((a, b) => a.title.localeCompare(b.title));
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpBottomSpace,
		KpiKpisArrayItem,
		KpiContentLayout,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter
	}
};
</script>
