import { registerAlertService } from "@/store/modules/alerts/services/alertService";
import Vue from "vue";
import Vuex, { ActionTree, MutationTree } from "vuex";
import alertsModule from "@/store/modules/alerts";
import kpisModule from "@/store/kpi/modules/kpis";
import kpiModule from "@/store/kpi/modules/kpi";
import reportingModule from "@/store/kpi/modules/reporting";
import userModule from "@/store/kpi/modules/user";
import RootState from "@/store/kpi/types/rootState";
import { PageModeType } from "@/store/kpi/types/pageModeType";
import { mutationTypes, actionTypes } from "@/store/kpi/types";
import kpiMainInfoModule from "@/store/kpi/modules/kpi/modules/mainInfo";
import kpiCriteriasInfoModule from "@/store/kpi/modules/kpi/modules/criteriasInfo";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import delegacyModule from "@/store/kpi/modules/delegacy";

Vue.use(Vuex);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new RootState(PageModeType.OK);
	}
}

const state = (new DefaultStateBuilder()).build();

const actions = <ActionTree<RootState, any>>{
	[actionTypes.handleServerError]({ commit }, ex: Error) {
		switch (ex.constructor) {
			case AccessForbiddenException:
				commit(mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED)
				break;
			default:
				console.error(ex);
				AlertHelper.handleGeneralRequestErrors(ex);
				break;
		}
	}
};

const mutations = <MutationTree<RootState>>{
	[mutationTypes.SET_IS_APP_LOADING](state, value) {
		state.isAppLoading = value;
	},
	[mutationTypes.SET_PAGE_MODE](state, value) {
		state.pageMode = value;
	},
	[mutationTypes.RESET_PAGE_MODE](state) {
		state.pageMode = PageModeType.OK;
	},
	[mutationTypes.SET_PAGE_MODE_NOT_FOUND](state) {
		state.pageMode = PageModeType.PAGE_NOT_FOUND;
	},
	[mutationTypes.SET_PAGE_MODE_ACCESS_FORBIDDEN](state) {
		state.pageMode = PageModeType.ACCESS_DENIED;
	}
};

const store = new Vuex.Store({
	state,
	mutations,
	actions,
	modules: {
		[userModule.namespace]: {
			...userModule
		},
		[kpisModule.namespace]: {
			...kpisModule
		},
		[kpiModule.namespace]: {
			...kpiModule
		},
		[kpiMainInfoModule.namespace]: {
			...kpiMainInfoModule
		},
		[kpiCriteriasInfoModule.namespace]: {
			...kpiCriteriasInfoModule
		},
		[reportingModule.namespace]: {
			...reportingModule
		},
		[delegacyModule.namespace]: {
			...delegacyModule
		},
		[alertsModule.namespace]: {
			...alertsModule
		}
	}
});

const registerSubscribers = (store: any) => {
	kpiModule.subscribe(store);
	kpisModule.initializeSubscribersManager(store);	
	reportingModule.initializeSubscribersManager(store);
	delegacyModule.initializeSubscribersManager(store);	
	kpiCriteriasInfoModule.initializeSubscribersManager(store);
};
registerAlertService(store);
registerSubscribers(store);

export default store;
