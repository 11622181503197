import { ReportingItem } from "@/store/kpi/modules/reporting/types/reportingItem";
import { isNil } from "lodash";

export interface ReportingEditableItem {
	userId: string;
	kpiId: string;
	selectedCriteriaId: string | null;
	position: string;
	selectedDepartmentId: string;
	completion: string;
	justification: string;
	laborRate: string;
	laborRateJustification: string;
	pecularity: string;
}

export class ReportingEditableItemMapper {
	static map(source: ReportingItem): ReportingEditableItem {
		return {
			userId: source.userId,
			kpiId: source.kpiId,
			position: source.position,
			selectedDepartmentId: source.department.id,
			selectedCriteriaId: source.criterias.find(x => x.isCompleted)?.id || null,
			completion: !isNil(source.completion) ? String(source.completion) : "",
			justification: source.justification || "",
			laborRate: !isNil(source.laborRate?.value) ? String(source.laborRate.value) : "100",
			laborRateJustification: source.laborRate?.justification || "",
			pecularity: source.pecularity || ""
		}
	}	
}
