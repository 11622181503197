import IPageState from "@/store/shared/base/types/pageState";
import { ApiKpi } from "@/api/kpi/types/apiKpi";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";
import { KpiPeriod } from "@/types/kpi/KpiPeriod";
import { Permissions } from "@/constants/permissions";

export default class KpiState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public id: string = "",
		public isFetchKpiLoading: boolean = false,
		public kpi: ApiKpi = {} as ApiKpi,
		public permissions: Permissions[] = [],
		public isPermissionsLoading: boolean = false,
		public isDictionariesLoading: boolean = false,
		public periods: KpiPeriod[] = [],
		public staffUsers: ApiKpiUser[] = []
	)
	{
	}
}
