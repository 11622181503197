import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import ReportingFilter from "@/store/kpi/modules/reporting/types/reportingFilter";
import { ApiKpiUser } from "@/api/kpi/types/apiKpiUser";
import { ApiKpiPeriod } from "@/api/kpi/types/apiKpiPeriod";
import FormState from "@/store/shared/form/models/formState";
import { ApiKpiReportUser } from "@/api/kpi/types/apiKpiReportUser";
import { ReportingEditableItem } from "@/store/kpi/modules/reporting/types/reportingEditableItem";
import { ApiKpisItem } from "@/api/kpi/types/apiKpisItem";
import { ApiReportingStaffPermissions } from "@/api/kpi/types/apiReportingStaffPermissions";
import { KpiDownloadScoreCardsRequest, KpiDownloadScoreCardsRequestService } from "@/types/kpi/apiDownloadScoreCardsRequest";
import ApiFileMeta from "@/api/types/storage/apiFileMeta";

export default class ReportingState implements IPageState {
	constructor(
		public listing: ListingModel<ApiKpiReportUser>,
		public sorting: SortingModel<String>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: ReportingFilter,
		public route: RouteState,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isKpisLoading: boolean = false,
		public isUsersLoading: boolean = false,
		public isPeriodsLoading: boolean = false,
		public isScoreCardDownloading: boolean = false,
		public kpis: ApiKpisItem[] = [],
		public users: ApiKpiUser[] = [],
		public periods: ApiKpiPeriod[] = [],
		public editableItems: ReportingEditableItem[] = [],
		public permissions: ApiReportingStaffPermissions[] = [],
		public isPermissionsLoading: boolean = false,
		public isScoreCardsDownloading: boolean = false,
		public downloadScoreCardsRequest: KpiDownloadScoreCardsRequest = KpiDownloadScoreCardsRequestService.getEmpty(),
		public isSigning: boolean = false,
		public evaluationReportFile: Blob | undefined = undefined,
		public evaluationReportFileMeta: ApiFileMeta | undefined = undefined,
		public isSignedFileLoading: boolean = false,
		public isFileLoading: boolean = false
	)
	{
	}
}
