import SubscribersManager from "@/store/manager/subscribersManager";
import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/kpi/modules/kpis/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { resolveAction, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import { ActionTree, GetterTree, MutationPayload, MutationTree } from "vuex";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import { Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import AbortService from "@/services/abortService";
import { KpiController } from "@/api/kpi";
import { RouteNames } from "@/router/kpi/routes";
import router from "@/router/kpi";
import routeTypes from "@/store/shared/route/types";
import KpisState from "@/store/kpi/modules/kpis/types/kpisState";
import KpisFilter from "@/store/kpi/modules/kpis/types/kpisFilter";
import KpisRouteQuery from "@/store/kpi/modules/kpis/types/kpisRouteQuery";
import KpisRouteService from "@/store/kpi/modules/kpis/services/kpisRouteService";
import { KpiStatusEnum } from "@/types/kpi/kpiStatusEnum";
import { saveAs } from "file-saver";
import { last, isNil } from "lodash";
import { formatFullName } from "@/utils/formatting";
import { ApiKpisItem } from "@/api/kpi/types/apiKpisItem";
import { ApiKpiQuarterEnum } from "@/api/kpi/types/ApiKpiQuarterEnum";
import KpisRouteParams from "@/store/kpi/modules/kpis/types/kpisRouteParams";
import { getYear } from "date-fns";
import contentDispositionParser from "content-disposition-parser";
import { i18n } from "@/plugins";
import { actionTypes as rootActionTypes } from "@/store/kpi/types";
import KpisMapper from "@/store/kpi/modules/kpis/mapper";

const abortService = new AbortService();
const kpiController = new KpiController(abortService);

const defaultRouteQuery = new KpisRouteQuery([], "", "", KpiStatusEnum.ALL);
const defaultRouteParams = new KpisRouteParams(0);

const routeService = new KpisRouteService(defaultRouteQuery, defaultRouteParams);

const updateListingBatchService = new BatchService(({ interval: 100 }));

const routeMixin = (new RouteMixinBuilder<KpisState>()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new KpisState(
			new ListingModel<ApiKpisItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			new KpisFilter(),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

let subscribersManager: SubscribersManager<KpisState>;

const getters = <GetterTree<KpisState, any>>{
	...listingMixin.getters,
	[getterTypes.availableUsers]: state => {
		return state.users.filter(user => state.listing.items.some(x => x.staff.some(y => y.id === user.id)));
	},
	[getterTypes.availableDepartments]: state => {
		return state.departments.filter(department => state.listing.items.some(x => x.staff.some(y => y.department.id === department.id)));
	},
	[getterTypes.filteredItems]: state => {
		return state.listing.items.map(kpi => {
			return {
				...kpi,
				staff: kpi.staff.filter(staff => {
					if(!(!state.filter.staffIds.length || state.filter.staffIds.includes(staff.id)))
						return false;
					
					if(!(!state.filter.departmentId || state.filter.departmentId === staff.department.id))
						return false;
					
					if(!kpi.title.toLocaleLowerCase().includes(state.filter.kpi.toLocaleLowerCase()))
						return false;
					
					return (state.filter.status === KpiStatusEnum.ALL ||
						(state.filter.status === KpiStatusEnum.ACTIVE && kpi.isActive) ||
						(state.filter.status === KpiStatusEnum.NOT_ACTIVE && !kpi.isActive));
				})
			};
		});
	},
	[getterTypes.formattedItems]: (state, getters) => {
		const result: any[] = [];
		
		(getters.filteredItems as ApiKpisItem[]).forEach(kpi => {
			result.push(...kpi.staff.map(staff => {
					const getWeight = (quarter?: ApiKpiQuarterEnum) => sortedCriterias.map(x => {
						const weight = x.periods.find(y => y.year === state.filter.year && y.quarter === quarter)?.weight;
						return isNil(weight) ? i18n.t("common.no") : weight;
					});
					const sortedCriterias = staff.criterias.sort((a, b) => a.sort - b.sort);
					
					return {
						id: kpi.id,
						title: kpi.title,
						staff: formatFullName(staff),
						department: staff.department.name,
						criterias: sortedCriterias.map(x => x.title),
						quarter1: getWeight(ApiKpiQuarterEnum.I),
						quarter2: getWeight(ApiKpiQuarterEnum.II),
						quarter3: getWeight(ApiKpiQuarterEnum.III),
						quarter4: getWeight(ApiKpiQuarterEnum.IV),
						year: getWeight()
					};
				})
			);
		});
		
		return result;
	},
	[getterTypes.isOneUserOnly]: (state, getters) => {
		if(state.filter.staffIds.length === 1)
			return true;
		
		const userId = state.listing.items[0]?.staff[0]?.id;
		
		if(!userId)
			return false;
		
		return state.listing.items.every(x => x.staff.every(y => y.id === userId));
	}
};

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<KpisState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<KpisState>(rootState, namespace);
	
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !mutation.payload.to.query.year) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.reconstituteRoute));
			} else if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRoute));
			break;
		// Фильтрация на фронте
		case resolveMutation(namespace, mutationTypes.RESET_FRONT_FILTER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STAFF_IDS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_DEPARTMENT_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_KPI):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STATUS):
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			break;
		// Фильтрация на бэке
		case resolveMutation(namespace, mutationTypes.SET_FILTER_YEAR):
			if(state.isInitialized)
				subscribersManager.commit(resolveMutation(namespace, mutationTypes.RESET_FRONT_FILTER));
		case resolveMutation(namespace, mutationTypes.RESET_FILTER):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
	}
};

const actions = <ActionTree<KpisState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRoute);
		await dispatch(actionTypes.reconstituteRoute);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		
		await dispatch(actionTypes.fetchPeriods);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let { kpis } = await kpiController.getKpisByYear(state.filter.year);
			
			commit(mutationTypes.SET_LISTING_ITEMS, kpis);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.processRoute]({ rootState, commit, dispatch, state }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeService.resolveRouteQuery(rootState.route.query);
		let routeParams = await routeService.resolveRouteParams(rootState.route.params);
		
		// Если при открытии страницы уже стоят фильтры, нужно сразу загрузить соответствующие справочники
		if(routeQuery.staffIds.length)
			await dispatch(actionTypes.fetchUsers);
		if(routeQuery.departmentId)
			await dispatch(actionTypes.fetchDepartments);
		
		if(state.filter.year !== routeParams.year)
			commit(mutationTypes.SET_FILTER_YEAR, routeParams.year);
		commit(mutationTypes.SET_FILTER_STAFF_IDS, routeQuery.staffIds);
		commit(mutationTypes.SET_FILTER_DEPARTMENT_ID, routeQuery.departmentId);
		commit(mutationTypes.SET_FILTER_KPI, routeQuery.kpi);
		commit(mutationTypes.SET_FILTER_STATUS, routeQuery.status);
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.KPIS,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.KPIS,
			params: routeService.resolveRouteParamsDictionary(state),
			query: routeService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	},
	async [actionTypes.fetchUsers]({ commit, state, dispatch }) {
		if(state.users.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_USERS_LOADING, true);
		
		try {
			const items = await kpiController.getStaffList();
			
			const users = items.map(x => ({ ...x, fullName: formatFullName(x) }));
			
			commit(mutationTypes.SET_USERS, users.sort((a, b) => a.fullName.localeCompare(b.fullName)));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_USERS_LOADING, false);
		}
	},
	async [actionTypes.fetchDepartments]({ commit, state, dispatch }) {
		if(state.departments.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);
		
		try {
			const items = await kpiController.getDepartments();
			
			commit(mutationTypes.SET_DEPARTMENTS, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.fetchPeriods]({ commit, state, dispatch }) {
		if(state.periods.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_PERIODS_LOADING, true);
		
		try {
			const items = await kpiController.getPeriods(true);
			const year = items.some(x => x.year === state.filter.year) ? state.filter.year : getYear(new Date());
			
			commit(mutationTypes.SET_PERIODS, items);
			commit(mutationTypes.SET_FILTER_YEAR, year);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_PERIODS_LOADING, false);
		}
	},
	async [actionTypes.downloadFile]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_EXCEL_DOWNLOADING, true);
		
		try {
			const { data, responseHeaders } = await kpiController.downloadRegistry(state.filter.year,
				KpisMapper.mapToApiDownloadRegistry(state));
			const { filename } = contentDispositionParser(responseHeaders["content-disposition"]);
			
			saveAs(data, filename);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_EXCEL_DOWNLOADING, false);
		}
	}
};

const mutations = <MutationTree<KpisState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_FILTER_YEAR](state, value) {
		state.filter.year = value;
	},
	[mutationTypes.SET_FILTER_STAFF_IDS](state, value) {
		state.filter.staffIds = value;
	},
	[mutationTypes.SET_FILTER_DEPARTMENT_ID](state, value) {
		state.filter.departmentId = value;
	},
	[mutationTypes.SET_FILTER_KPI](state, value) {
		state.filter.kpi = value;
	},
	[mutationTypes.SET_FILTER_STATUS](state, value) {
		state.filter.status = value;
	},
	[mutationTypes.RESET_FILTER](state) {
		state.filter = new KpisFilter(last(state.periods)!.year);
	},
	[mutationTypes.RESET_FRONT_FILTER](state) {
		state.filter = new KpisFilter(state.filter.year);
	},
	[mutationTypes.SET_IS_USERS_LOADING](state, value) {
		state.isUsersLoading = value;
	},
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_IS_PERIODS_LOADING](state, value) {
		state.isPeriodsLoading = value;
	},
	[mutationTypes.SET_IS_EXCEL_DOWNLOADING](state, value) {
		state.isExcelDownloading = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_PERIODS](state, value) {
		state.periods = value;
	},
	[mutationTypes.SET_USERS](state, value) {
		state.users = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const kpisModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default kpisModule;
