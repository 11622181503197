import { ApiKpiPeriod, ApiKpiPeriodMapper } from "@/api/kpi/types/apiKpiPeriod";
import { KpiMainInfo } from "@/store/kpi/modules/kpi/modules/mainInfo/types/kpiMainInfo";
import { ApiCriteria, ApiCriteriaMapper } from "@/api/kpi/types/apiCriteria";

export interface ApiUpdateKpiRequest {
	title: string;
	methodology: string;
	criterias: ApiCriteria[];
	staffIds: string[];
	periods: ApiKpiPeriod[];
	isRegular: boolean;
}

export class ApiUpdateKpiRequestMapper {
	static map(source: KpiMainInfo): ApiUpdateKpiRequest {
		return {
			...source,
			criterias: source.criterias.map(x => ApiCriteriaMapper.map(x)),
			periods: source.periods.map(x => ApiKpiPeriodMapper.map(x))
		};
	}
}
